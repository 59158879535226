export function overrideDefaultToolGraphics (){
    var tools = document.getElementsByClassName("toolbar-button thin-button")
    for ( var i=0; i<tools.length; i++) {
        var faIconChild = document.createElement('i')
        if([...tools][i].title==="Text"){
            faIconChild.className = "fa-solid fa-keyboard"
        }else if ([...tools][i].title==="Pan") {
            faIconChild.className = "fa-solid fa-up-down-left-right"
        }else if ([...tools][i].title==="Zoom out") {
            faIconChild.className = "fa-solid fa-magnifying-glass-minus"
        }else if ([...tools][i].title==="Zoom in") {
            faIconChild.className = "fa-solid fa-magnifying-glass-plus"
        }else{
            faIconChild.className = "fa-solid fa-"+ [...tools][i].title.toLowerCase()
        }
        tools[i].style.backgroundImage = null
        tools[i].appendChild(faIconChild)
    }
}

export function pointSize (shapes, locationIndex, minCoordinate) {
    var matchedShapes = shapes.filter(shape=>shape.data.pointCoordinatePairs.filter(x=>x[locationIndex]===minCoordinate).length>0)
    if(matchedShapes.length>0){
        return matchedShapes.some(shape=>shape.className==="ErasedLinePath")?
            matchedShapes.filter(shape=>shape.className==="ErasedLinePath")[0].data.pointSize/2
            :
            matchedShapes.filter(shape=>shape.className==="LinePath")[0].data.pointSize/2
    }else{
        return 1;
    }
}

export function minCoordinate (array, locationIndex) {
    return Math.min.apply(null, array.map(x=>x[locationIndex]))
}