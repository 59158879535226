import { useSelector, useDispatch } from "react-redux";
import { FloatingLabel, Form, Tabs, Tab, Dropdown, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { lazyLoad } from "../../components/Table/lazyLoad";

import Table from "../../components/Table";
import Searchbar from "../../components/SubNav/searchbar";
import UserAPI from "../../../API/users"
import InventoryAPI from "../../../API/inventory"
import LazyLoadList from "../../components/Table/lazyLoadList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { copyToClipboard } from "../inputInteraction";

export default function GroupDetails () {

    const dispatch = useDispatch();
    const tgtGroup = useSelector(state=>state.tgtGroup)
    const srcGroup = useSelector(state=>state.srcGroup)
    const myGroup = useSelector(state=>state.myGroup)

    const keys = tgtGroup._id!==myGroup._id?['users', 'inventory_items']:['users']
    const roles =['Admin', 'User']
    const [users, setUsers] = useState([]);
    const [resources, setResources] = useState([]);
    const [groupResources, setGroupResources] = useState([]);
    const [key, setKey] = useState(keys[0]);
    const filters = useSelector(state=>state.filters);

    const [pageLoad, setPageLoad] = useState(1);
    const [prevInnerHeight, setPrevInnerHeight] = useState(0);


    const updateTargetData = (types, update) => {
        if(typeof types === 'string'){
            dispatch({type:'SET_TGT_GROUP', payload: {
                ...tgtGroup,
                [types]:update
            }})
        }else{
            let updates = new Object;
            types.map((type, index)=>
            updates = {
                ...updates,
                [type]:update[index]??[]
            })
            dispatch({type:'SET_TGT_GROUP', payload: {
                ...tgtGroup,
                ...updates
            }})
        }
    }
    useEffect(()=>{
        !tgtGroup.parent_groups&&updateTargetData('parent_groups', srcGroup._id)
        dispatch({type: 'SET_ALLOW_SUBMIT', payload: (tgtGroup.name&&tgtGroup.name.length!==0)})
    },[tgtGroup])
    useEffect(()=>{
        //auto increment pageLoad if loaded entries < 40*pageLoad
    },[filters])

    const entryTemplate = key==='users'?
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Full name",
            key:'fullname',
            col:'',
        },
        {
            label:"Role",
            key:'conditionalComponent',
            col:'4',
            conditionalComponent: ({data})=>
            [...tgtGroup.users, ...tgtGroup.admins].some(x=>x===data._id)&&
            <Dropdown>
                <Dropdown.Toggle 
                    variant="outline-secondary" 
                    className="w-100"
                    disabled={data.groups.some(group=>group.role==="Admin"&&group._id!==tgtGroup._id)||tgtGroup._id===myGroup._id}
                >
                    {tgtGroup.admins.some(x=>x===data._id)?'Admin':'User'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {roles.map((role, index)=>
                        <Dropdown.Item
                            key={index}
                            disabled={role==='Admin'?tgtGroup.admins.some(x=>x===data._id):tgtGroup.users.some(x=>x===data._id)}
                            onClick={()=>{
                                if(role==='Admin'){
                                    updateTargetData(['admins', 'users'], [[...tgtGroup.admins, data._id], tgtGroup.users.filter(x=>x!==data._id)])
                                }else{
                                    updateTargetData(['users', 'admins'], [[...tgtGroup.users, data._id], tgtGroup.admins.filter(x=>x!==data._id)])
                                }
                            }}
                        >{role}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        },
        {
            label:"Solution",
            key:'conditionalComponent',
            col:'3',
            conditionalComponent: ({data})=>
            tgtGroup.users.some(x=>x===data._id)&&
            <Form className="d-flex justify-content-center align-items-center h-100">
                <Form.Check
                    type="switch"
                    checked={tgtGroup.allow_solution.some(x=>x===data._id)}
                    onChange={()=>
                        tgtGroup.allow_solution.some(x=>x===data._id)?
                        updateTargetData("allow_solution", tgtGroup.allow_solution.filter(x=>x!==data._id))
                        :
                        updateTargetData("allow_solution", [...tgtGroup.allow_solution, data._id])
                    
                    }
                />
            </Form>
        },
    ]
    :
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Book Title",
            key:'Title',
            col:'',
        },
        {
            label:"Subject",
            key:'Subject',
            col:'3',
        },
        {
            label:"Level",
            key:'Level',
            col:'2',
        },
        
    ]

    return (
        <div className="text-dark">
            <div className="row m-0">
            <FloatingLabel
                label={'Group name'}
                className="secondary-font text-dark mb-3 col"
            >
                <Form.Control
                    type="text" 
                    placeholder="group name"
                    disabled={myGroup._id===tgtGroup._id}
                    value={tgtGroup.name??[]}
                    className="border-0 border-bottom rounded-0"
                    onChange={(e)=>updateTargetData("name", e.target.value)}
                />
            </FloatingLabel>
            {tgtGroup._id&&<FloatingLabel
                label={'Invitation code'}
                className="secondary-font text-dark mb-3 col-6 d-flex"
            >
                <Form.Control
                    type="text" 
                    value={tgtGroup.invitation_code}
                    className="border-0 border-bottom rounded-0"
                />
                <Button variant='outline-secondary' className="mt-3"
                    onClick={()=>copyToClipboard(tgtGroup.invitation_code)}
                >
                    <FontAwesomeIcon icon={faCopy}/>
                </Button>
            </FloatingLabel>}
            </div>
            <div className="my-2 py-2 px-3" style={{background:"rgba(0,0,0,0.1)"}}>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                variant='pills'
                className="pt-2 secondary-font"
            >
                {keys.map((k, index)=>
                <Tab 
                    key={index} 
                    eventKey={k} 
                    title={k==='users'?
                        "Members ("+parseFloat(tgtGroup.admins.length+tgtGroup.users.length)+")"
                        :
                        "Inventory Items ("+(tgtGroup.sync_with_root==="true"?"sync":tgtGroup.inventory_items.length)+")"
                    } 
                    className="mt-2"
                >
                    {k!=='users'&&
                    <Form className="my-2">
                        <Form.Check
                            type="switch"
                            checked={tgtGroup.sync_with_root==="true"?true:false}
                            className="secondary-font text-dark text-start"
                            label="Sync inventory items"
                            onChange={()=>updateTargetData("sync_with_root", tgtGroup.sync_with_root==="true"?"false":"true")}
                        />
                    </Form>}
                    {!(k==='users'||tgtGroup.sync_with_root==="true")||(k==='users')?
                    <>
                        <Searchbar
                            type={k==='users'?"users":"resources"}
                        />
                        <Table
                            tableEntryTemplates={entryTemplate}
                            tableMenuActions={[]}
                            className="mt-3 w-100"
                            onScroll={(e)=>{
                                lazyLoad(e, setPageLoad, prevInnerHeight)&&setPrevInnerHeight(e.target.scrollHeight)
                            }}
                        >
                            <LazyLoadList
                                pageLoad={k!=='users'?pageLoad:1}
                                tableEntryTemplates={entryTemplate}
                                entries={resources}
                                extractFilter={(e)=>e.filter(item=>k==='users'?item.name==='users':item.name==='resources')[0].value}
                                groupEntries={k==='users'?users:groupResources}
                                setEntries={setResources}
                                setGroupEntries={k==='users'?setUsers:setGroupResources}
                                isChecked={(e)=>k==='users'?
                                    [...tgtGroup.users, ...tgtGroup.admins].some(x=>x===e)
                                    :
                                    tgtGroup.inventory_items.some(x=>x===e)
                                }
                                onEntryCheck={(e)=>{
                                    if(k==='users'){
                                        if([...tgtGroup.users, ...tgtGroup.admins].some(x=>x===e)){
                                            tgtGroup.users.some(x=>x===e)&&updateTargetData(['users','allow_solution'], [tgtGroup.users.filter(x=>x!==e), tgtGroup.allow_solution.filter(x=>x!==e)])
                                            tgtGroup.admins.some(x=>x===e)&&updateTargetData('admins', tgtGroup.admins.filter(x=>x!==e))
                                        }else{
                                            updateTargetData('users', [...tgtGroup.users, e])
                                        }
                                    }else{
                                        tgtGroup.inventory_items.some(x=>x===e)?
                                        updateTargetData('inventory_items', tgtGroup.inventory_items.filter(x=>x!==e))
                                        :
                                        updateTargetData('inventory_items', [...tgtGroup.inventory_items, e])
                                    }
                                }}
                                style={{height:"50vh"}}
                                groupEntriesIndex={k==='users'?UserAPI.userIndex:InventoryAPI.groupInventoryIndex}
                                entriesIndex={key!=='users'&&InventoryAPI.inventoryIndex}
                                actions={updateTargetData}
                            />
                        </Table>
                    </>
                    :
                    <div>

                    </div>}
                </Tab>
                )}
            </Tabs>
            </div>
        </div>
    )
}