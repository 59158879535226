import Worksheet from "../../../components/Worksheet";
import API from "../../../../API/groups";
import LeftSlideToggleIcon from "../../../components/leftSlideToggleIcon";
import DeleteObjectModel from "../../../components/Models/deleteObjectModel";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";

export default function Banner () {

    const dispatch = useDispatch();
    const [banner, setBanner] = useState(false)
    const token = useSelector(state=>state.token)
    const srcGroup = useSelector(state=>state.srcGroup)

return (
    srcGroup._id!=="1"?<div className="row p-0">
        <div className="col-xl-5 col-12 text-start py-3">
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Change <b>{srcGroup.name}</b> banner</Form.Label>
            <Form.Control 
                type="file"
                onChange={(e)=>setBanner(e.target.files[0])
            }/>
        </Form.Group>
            <p>Banner uploades and applies here will be displayed on all resources that categorizes as supplemental workbook. Ensure a dimension of 937 x 274px at 72 DPI to optimal image quality.</p>
            <Button 
                className="me-1" 
                disabled={!banner}
                onClick={()=>{
                    dispatch({type: "SHOW_PRELOAD", payload: true})
                    API.addGroup(token, {_id:srcGroup._id, banner:banner}).then((res)=>{
                        setBanner(false)
                        dispatch({type: "SET_SRC_GROUP", payload: res.data[0]})
                        dispatch({ type: 'SET_NOTIFICATION', payload: {
                            show: true,
                            code: 200,
                            message: 'Banner updated!'
                        }})
                        dispatch({type: "SHOW_PRELOAD", payload: false})
                    })
                }}    
            >Apply</Button>
            <LeftSlideToggleIcon
                outline
                variant="danger"
                disabled={!srcGroup.banner}
                label={"Remove banner"}
                slideInContent={()=>(
                    <DeleteObjectModel
                        model={"group"}
                        submit={()=>
                            API.updateGroup(token, {_id:srcGroup._id, banner:srcGroup.banner}).then((res)=>{
                                dispatch({ type: 'SET_NOTIFICATION', payload: {
                                    show: true,
                                    code: 200,
                                    message: 'Banner removed sucessfully!'
                                }})
                                setBanner(false)
                                dispatch({type: "SET_SRC_GROUP", payload: res.data[0]})
                        })}
                    />
                )}
            />
        </div>
        <div className="col-xl-7 col-12">
            <Worksheet
                className="w-100 drop-shadow my-3"
                category="sample"
                src="/assets/sample.jpg"
                previewBanner={banner}
                bannerUploaded={
                    banner?
                        URL.createObjectURL(banner)
                        :
                        srcGroup.banner
                }
            />
        </div>
    </div>
    :
    <div className="d-flex align-items-center h-100 w-100 justify-content-center">
        <p>Select a group other than Vendor to start.</p>
    </div>
)
}