import { useSelector } from "react-redux";
import Worksheet from "./Worksheet"

export default function RightSlide (props) {
    const {
        items,
        category,
        showSolutionsSlide,
    } = props;
    const srcGroup = useSelector(state=>state.srcGroup)
    const screen = useSelector(state=>state.screen)
    return (
        <div className={(screen.indexOf('editTool')<0?"shadow-overlay":"position-absolute")+" col-xxl-6 col-xl-8 col-12 h-100 bg-secondary overflow-y-scroll drop-shadow"}
            style={{
                zIndex:10000, 
                right:'-100%',
                transition: "0.3s ease"
            }}
            ref={showSolutionsSlide}
        >
            {items&&items.map((page, index)=>
                <div key={index} className="px-4 my-3">
                    <Worksheet 
                        src={'vendors-upload/'+page} 
                        className="w-100"
                        category={category}
                        bannerUploaded={srcGroup.banner}
                    />
                </div>
            )}
        </div>
    )
}
