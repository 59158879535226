import ObjectsNavigation from './objectsNavigations'
import SubNavActionButtons from './actionButtons';
import { localDeployment } from '../../../API/config';
import Searchbar from './searchbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';


export default function SubNavContainer (props) {

    const {
        allowSolutions,
        showSolutionsSlide,
    } = props;

    const location = useLocation();
    const token = useSelector(state=>state.token)
    const role = useSelector(state=>state.role)
    const screen = useSelector(state=>state.screen)
    const srcGroup = useSelector(state=>state.srcGroup)
    const myGroup = useSelector(state=>state.myGroup)
    const myInfo = useSelector(state=>state.myInfo)
    const currentResource = useSelector(state=>state.currentResource)
    const bookmark = useSelector(state=>state.bookmark)

    return (
        <div className='bg-light row drop-shadow justify-content-between align-items-center h-100 w-100'
                style={{zIndex: '100'}}
            >
                <div className="row justify-content-start align-items-center">
                    <div className='col d-flex align-items-center'>
                        <SubNavActionButtons
                            location={location}
                            token={token}
                            screen={screen}   
                            srcGroup={srcGroup}
                            currentResource={currentResource}
                            bookmark={bookmark}
                            allowPrint={myInfo.allow_print}
                        />
                        {
                        (
                        screen.indexOf('editTool')<0&&
                        screen.indexOf('resourceIndexBySection')<0&&
                        screen.indexOf('vendor')<0)&&
                        !(role==='user'&&myGroup._id==='1')&&
                        location.pathname.indexOf('SearchResult')<0&&
                        screen.indexOf('subscription')<0&&
                        srcGroup&&!localDeployment&&
                            <ObjectsNavigation type={"group"}/>
                        }
                        {screen.indexOf('assignment')>=0
                        &&role==='admin'
                        &&
                            <>
                            <FontAwesomeIcon 
                                className='col-auto mx-3'
                                icon={faChevronRight}
                            />
                            <ObjectsNavigation type={"user"}/>
                            </>
                        }
                    </div>
                    {allowSolutions&&allowSolutions.length>0&&screen.indexOf('editTool')>0&&
                    <div
                        className='col-auto'
                    >
                        <Button
                            variant='outline-secondary'
                            onClick={()=>showSolutionsSlide.current!==0&&showSolutionsSlide.current.style.right==='0px'?
                                showSolutionsSlide.current.style.right='-100%':showSolutionsSlide.current.style.right='0px'}
                        >
                            View solution
                        </Button>
                    </div>}
                    {(screen.indexOf('editTool')<0&&screen.indexOf('resourceIndexBySection')<0&&screen.indexOf('setting')<0)&&
                    <Searchbar
                        className="col-5 align-items-center"
                        toResultPage={screen.indexOf('inventory')>=0}
                        type={
                            (screen.indexOf('resources')>=0||
                            screen.indexOf('subscription')>=0
                            )?'resources':screen
                        }
                    />}
                </div>
                
            </div>

    )
}