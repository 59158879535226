import { useSelector } from "react-redux";
import Worksheet from "../../../../components/Worksheet";

export default function ExpandedView(props) {
    const {
        worksheet,
        setShowExpanded,
        solution,
        category,
        navHeight
    } = props;
    const srcGroup = useSelector(state=>state.srcGroup)
    return(
        <div
            className="position-fixed shadow-overlay w-100 overflow-y-scroll py-5 pointer"
            style={{zIndex:'1000000', height:'calc(100% - '+navHeight+')'}}
            onClick={()=>setShowExpanded(false)}
        >
            <div className="row align-items-center justify-content-center">
            <div
                className={(solution?"col-xl-6":"col-10")}
            >
            <Worksheet
                bannerUploaded={srcGroup.banner}
                category={category}
                src={worksheet}
            />
            </div>
            {solution&&
            <div
                className="col-xl-6 col-10 my-5" 
            >
            <Worksheet
                category={category}
                bannerUploaded={srcGroup.banner}
                src={solution}
            />
            </div>
            }
           </div>
        </div>
    )
}