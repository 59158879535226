import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { FloatingLabel, Form, Dropdown, InputGroup, Button } from "react-bootstrap";
import { randomize, copyToClipboard } from "../inputInteraction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faRandom } from "@fortawesome/free-solid-svg-icons";
import { localDeployment } from "../../../API/config";

export default function UserDetails () {

    const dispatch = useDispatch();
    const screen = useSelector(state=>state.screen)
    const tgtUser = useSelector(state=>state.tgtUser)
    const srcGroup = useSelector(state=>state.srcGroup)
    const myGroup = useSelector(state=>state.myGroup)
    const roles =['Admin', 'User']

    const updateTargetData = (type, update) => {
        dispatch({type:'SET_TGT_USER', payload: {
            ...tgtUser,
            [type]:update
        }})
    }
    const [validate, setValidate] = useState({
        level:'danger',
        message: ""
    })
    const requiredInputFields = [
        {
        placeholder: "First name",
        type:"text",
        key: "firstname"
        },
        {
        placeholder: "Lastname",
        type:"text",
        key: "lastname"
        },
        {
        placeholder: "Username or email",
        type:"text",
        key: "username"
        },
        {
        placeholder: "",
        type:"blank",
        key: ""
        },
        {
        placeholder: "Password",
        type:"password",
        key: "password"
        },
        {
        placeholder: "Retype Password",
        type:"password",
        key: "password_validate"
        },
    ]
    useEffect(()=>{
        (!tgtUser.groups||tgtUser.groups.length<=0)&&
        updateTargetData('groups', [{_id:srcGroup._id, name:srcGroup.name, role:'User'}])
        dispatch({type: 'SET_ALLOW_SUBMIT', payload: (
            tgtUser._id||
            tgtUser.firstname&&tgtUser.firstname!==0&&
            tgtUser.lastname&&tgtUser.lastname!==0&&
            tgtUser.username&&tgtUser.username!==0&&
            (tgtUser.password&&tgtUser.password!==0)&&
            !(tgtUser.password_validate&&tgtUser.password_validate!==tgtUser.password)
        )})
        if(tgtUser.password_validate&&tgtUser.password_validate!==tgtUser.password){
            setValidate({
                ...validate,
                message: "Retype password doesn't match"
            }) 
        }else{
            setValidate({
                ...validate,
                message: ""
            }) 
        }
    },[tgtUser])
    return (
        <div className="text-dark row">
            {requiredInputFields.map((field, index)=>
            field.type!=="blank"?
                !(tgtUser._id&&(field.type==='password'))&&
                <FloatingLabel
                    key={index}
                    label={field.placeholder}
                    className="secondary-font text-dark mb-3 px-2 col-6"
                >
                    <Form.Control
                        type={field.type}
                        placeholder={field.placeholder}
                        disabled={field.key==='username'&&tgtUser._id}
                        value={tgtUser[field.key]??[]}
                        className="border-0 border-bottom rounded-0"
                        onChange={(e)=>updateTargetData(field.key, field.key==="username"?e.target.value.toLowerCase():e.target.value)}
                    />
                </FloatingLabel>
            :
            tgtUser._id&&screen.indexOf('user')>=0&&<div className="col-6 text-start d-flex" key={index}>
            <InputGroup className="border-0 align-items-center justify-content-center mb-3">
                <Button variant="secondary" onClick={()=>updateTargetData('password', randomize(8))}>
                    <FontAwesomeIcon icon={faRandom} className="pe-2"/>
                    {!tgtUser.password&&"Reset password"}
                </Button>
                {tgtUser.password&&
                <>
                <Form.Control
                    value={tgtUser.password}
                    onChange={(e)=>updateTargetData('password', e.target.value)}
                />
                <Button variant="outline-secondary" onClick={()=>copyToClipboard(tgtUser.password)}>
                    <FontAwesomeIcon icon={faCopy}/>
                </Button>
                </>
                }
            </InputGroup>
            </div>
            )}
            <p className={"m-0 text-start text-"+validate.level}>{validate.message}</p>
            {screen.indexOf('user')>=0?
            <div className="mt-2 py-2 px-3" style={{background:"rgba(0,0,0,0.1)"}}>
            {(srcGroup._id!=="1"&&tgtUser.groups.filter(items=>items._id!==myGroup._id).length>0||(tgtUser._id&&tgtUser.groups.filter(items=>items._id!==myGroup._id).length>0&&tgtUser.groups[0]._id!=="1"))&&
            <>
            <p className="text-start my-2">Roles</p>
                <div>
                    {tgtUser.groups.filter(items=>items._id!==myGroup._id).map((group, index)=>
                    <div key={index} className="row justify-content-start align-items-center text-start mb-2">
                    <Dropdown className="col-3">
                        <Dropdown.Toggle 
                            variant="outline-secondary"
                            className="w-100"
                        >
                            {group.role}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {roles.map((role, rindex)=>
                                <Dropdown.Item
                                    key={rindex}
                                    disabled={tgtUser.groups.some(group=>group.role==="Admin")&&role==="Admin"}
                                    onClick={()=>{
                                        let prevState = JSON.parse(JSON.stringify(tgtUser.groups))
                                        prevState[index].role=role
                                        updateTargetData('groups', prevState)
                                    }}
                                >{role}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p className="col m-0 p-0">of {group.name}</p>
                    </div>
                    )}
                </div>
            </>
            }
            {localDeployment&&<p className="text-start my-2">Permission</p>}
            <div className="row">
            {localDeployment&&<Form className="col-auto text-start">
                <Form.Check
                    type="switch"
                    checked={tgtUser.allow_print??false}
                    label={"Allow print on all admin groups"}
                    onChange={()=>
                        updateTargetData("allow_print", !tgtUser.allow_print)
                    }
                />
                {/* <Form.Check
                    type="switch"
                    checked={tgtUser.allow_solution.action==="allow"??false}
                    label={"Alow solution on "+srcGroup.name +" group"} 
                    onChange={()=>
                        updateTargetData("allow_solution", {
                            ...tgtUser.allow_solution,
                            action: tgtUser.allow_solution.action==="allow"?"deny":"allow"
                        })
                    }
                /> */}
            </Form>}
            </div>
        </div>
        :
        ''
        }
        </div>
    )
}